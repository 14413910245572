<script setup>
import { onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'

import { NSelect, NButton } from 'naive-ui'
import NextTable from '@/components/widgets/simple-components/table/NextTable'
import { useIncidentsStore } from '@/store/incidents'
import moment from 'moment'

const incidentsStore = useIncidentsStore()
const incidentsStoreRefs = storeToRefs(incidentsStore)

const columns = [
  { key: 'id', title: '№', width: '80px', sortable: true, sortOrder: 'DESC', fontSize: '0.7rem', padding: '10px' },
  {
    key: 'registration_date',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Дата регистрации',
    render: (row, item) => moment(item).format('DD.MM.YYYY')
  },
  {
    key: 'department',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Подразделение'
  },
  {
    key: 'place',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Место'
  },
  {
    key: 'medType',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Направление',
    render: (row, item) => item?.text
  },
  {
    key: 'process',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Раздел ВКК',
    render: (row, item) => item?.text
  },
  {
    key: 'actionCategory',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Вид нежелательного события',
    render: (row, item) => item?.name
  },
  {
    key: 'description',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Описание'
  },
  {
    key: 'consequence',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Последствия'
  },
  {
    key: 'measures',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Принятые меры'
  },
  {
    key: 'registrator',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Кто зарегистрировал',
    render: (row, item) => item?.name
  },
  {
    key: 'responsible',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Ответственный',
    render: (row, item) => item?.name
  },
  {
    key: 'statusName',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Статус'
  },
  {
    key: 'elimination_date',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Дата устранения',
    render: (row, item) => item ? moment(item).format('DD.MM.YYYY') : ''
  },
  {
    key: 'reaction_index',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Индекс реагирования',
  },
  {
    key: 'sending_date_rkn',
    fontSize: '0.7rem',
    padding: '10px',
    title: 'Дата извещения',
    render: (row, item) => item ? moment(item).format('DD.MM.YYYY') : ''
  }
]

const tableData = incidentsStoreRefs.list

const incidentsTypes = [
  {
    "id": 1,
    "value": 1,
    "name": "Новое",
    "label": "Новое",
    "key": "new"
  },
  {
    "id": 2,
    "value": 2,
    "name": "В работе",
    "label": "В работе",
    "key": "in_process"
  },
  {
    "id": 3,
    "value": 3,
    "name": "Закрыто",
    "label": "Закрыто",
    "key": "closed"
  }
]
const expandedDescriptions = ref({});
const dateStart = ref('')
const dateEnd = ref('')

onMounted(() => {
  fetchData()
})

const fetchData = () => {
  incidentsStore.fetch({
    date_start: dateStart.value,
    date_end: dateEnd.value
  })
}

const incidentsTypeSelect = (value) => {
  // console.log('incidentsTypeSelect', value);
}

const descriptionsClick = (row) => {
  expandedDescriptions.value[row.id] = !expandedDescriptions.value[row.id];
}

const changeStartDate = (e) => {
  dateStart.value = e.target.value
  fetchData()
}

const changeEndDate = (e) => {
  dateEnd.value = e.target.value
  fetchData()
}

// const loadMore = () => {
//   const isFetchMore = true

//   incidentsStore.fetch({
//     date_start: dateStart.value,
//     date_end: dateEnd.value
//   }, isFetchMore)
// }
</script>

<template>
  <div class="page">
    <div class="page-header">
      <div class="page-header__left">
        <div class="page-header__heading">Журнал учета нежелательных событий</div>
      </div>
    </div>
    <div class="content">
      <div class="d-flex justify-content-between mb-4">
        <div class="d-flex flex-wrap gap-3">
          <div class="dropdown ">
            <p>Период</p>
            <div class="d-flex align-items-center">
              <input class="date-input" type="date" @change="changeStartDate">
              <div class="filter-delimiter">-</div>
              <input class="date-input" type="date" @change="changeEndDate">
            </div>
          </div>
          <div class="dropdown">
            <p>Статус</p>
            <NSelect
              size="large"
              clearable
              style="width: 280px"
              :options="incidentsTypes"
              :on-update:value="incidentsTypeSelect"
            />
          </div>
        </div>
        <!-- <RouterLink :to="`/incidents/create`">
          <NButton type="primary"> Регистрация НС </NButton>
        </RouterLink> -->
      </div>
      <NextTable
        class="table-responsive-xl"
        :columns="columns"
        :table-data="tableData"
        :loading="isLoading"
        striped
        @change:sort="changeSort"
      >
        <template #id="{ value }">
          <RouterLink :to="`/incidents/${value}`">{{ value }}</RouterLink>
        </template>

        <template #registration_date="{ value, row }">
          <!-- <RouterLink :to="`/incidents/${row.id}`" class="!text-gray-800">
            {{ value }}
          </RouterLink> -->
          <span>{{ value }}</span>
        </template>

        <template #department="{ value }">
          <span>{{ value?.name || "—" }}</span>
        </template>

        <template #place="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #med_type_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #process_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #action_category_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #description="{ value, row }">
          <span class="cursor-pointer" :class="{ 'multiline-cut': !expandedDescriptions[row.id] }" @click="descriptionsClick(row)">
            {{ value }}
          </span>
        </template>

        <template #consequence="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #measures="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #registrator="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #responsible_id="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #status="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #elimination_date="{ value }">
          <span>{{ value }}</span>
        </template>

        <template #reaction_index="{ value, row }">
          <div class="flex justify-content-center">
            <div v-if="value == 0" style="background: #717476; width: 20px; height: 20px; border-radius: 100%;" />
            <div v-else style="background: #dc3545; width: 20px; height: 20px; border-radius: 100%;" />
            <div class="pl-2">{{ row.reaction_index_text }}</div>
          </div>
        </template>

        <template #sending_date_rzn="{ value }">
          <span>{{ value }}</span>
        </template>
      </NextTable>

      <!-- <NButton class="mt-4" type="primary" @click="loadMore">Загрузить больше НС</NButton> -->
      <!-- <div v-if="count / perPage > 1" class="pagination-container">
        <Paginate
          :key="perPage"
          :page-count="Math.ceil(count / perPage)"
          :page-range="5"
          :margin-pages="2"
          :next-text="''"
          :prev-text="''"
          :click-handler="fetch"
        />
      </div> -->
      <!-- <NextAlert v-else type="error" title="Произошла ошибка" :text="error" /> -->
    </div>
  </div>
</template>


<style scoped>
.date-input {
  font-size: 16px;
  line-height: 11px;
  padding: 9px 15px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 3px;
}

span.red {
  color: red;
}

.multiline-cut {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
  white-space: pre-wrap;
}

.cursor-pointer {
  cursor: pointer;
}
</style>